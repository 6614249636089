<template>
    <el-dialog tabindex="-1" width="800px" top="40px" title="Granska Förvaltningsberättelsen" :visible.sync="visible" :before-close="closeModal" :close-on-click-modal="false">
        <div class="border border-indigo-600 p-4" v-if="annualReportData" style="min-height: 1200px;">
            <div class="flex justify-between p-8">
                <div class="flex flex-col">
                    <p>{{ clientInfo.name }}</p>
                    <p>Org.nr {{ clientInfo.registrationNumber }}</p>
                </div>
                <p>2 (8)</p>
            </div>
            <div class="px-8 pb-8">
                <p class="text-sm break-normal">{{ annualReportData.annualReportDescription }}</p>
                <p v-if="annualReportData.annualReportSek && annualReportData.addAnnualReportIsSek" class="pt-4 text-sm pr-5 break-normal">{{ annualReportData.annualReportSek }}</p>
                <h1 class="pt-6 text-lg font-bold">Förvaltningsberättelse</h1>
                <p class="pt-4 text-base font-bold">{{ annualReportData.businessTitle }}</p>
                <div v-if="annualReportData.addGeneralBusinessInfo">
                    <h2 class="pt-4 font-bold font-sm">{{ annualReportData.generalBusinessTitle }}</h2>
                    <p class="pt-2 text-sm pr-5 break-normal">{{ annualReportData.generalBusinessInfo }}</p>
                </div>
                <div v-if="annualReportData.addResidenceInfo">
                    <p class="pt-2 text-sm">{{ annualReportData.residenceInfo }}</p>
                </div>
                <div v-if="annualReportData.addFinancialYearEventTitle">
                    <h2 class="pt-4 font-bold font-sm">{{ annualReportData.financialYearEventTitle }}</h2>
                    <p class="pt-2 text-sm pr-5 break-normal">{{ annualReportData.financialYearEventInfo }}</p>
                </div>

                <div class="pt-4" v-if="annualReportData.addYearlyOverview">
                    <div class="pt-4" v-if="annualReportData">
                        <table style="border-collapse: collapse;">
                            <thead>
                                <tr>
                                    <th
                                        v-for="(year, rowIndex) in annualReportData.yearlyOverview.years"
                                        :key="rowIndex"
                                        :class="{
                                            'year-header': rowIndex === 0,
                                            'year-cell': rowIndex !== 0,
                                        }"
                                    >
                                        <h2 class="pt-4 font-bold text-sm">{{ year }}</h2>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-sm">
                                    <td class="amount-cell" v-for="(value, rowIndex) in annualReportData.yearlyOverview.months" :key="rowIndex">
                                        {{ value }}
                                    </td>
                                </tr>
                                <tr class="text-sm" v-for="(value, rowIndex) in annualReportData.yearlyOverview.rows" :key="rowIndex">
                                    <td class="description-cell">
                                        {{ value.description }}
                                    </td>
                                    <td v-for="(decimal, colIndex) in value.amounts" :key="colIndex" class="amount-cell">
                                        <p v-if="rowIndex != 2">{{ decimal | swedishNumberFormat(true) }}</p>
                                        <p v-else>{{ decimal.toFixed(1) }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="annualReportData.yearlyOverview.showYearlyOverviewComment" class="pt-4 text-sm">{{ annualReportData.yearlyOverview.yearlyOverviewComment }}</div>
                </div>
                <div v-if="annualReportData.addChangesInEquity">
                    <h2 class="pt-4 font-bold font-sm">Förändringar i eget kapital</h2>
                    <div class="pt-4 text-sm" v-if="annualReportData">
                        <table style="border-collapse: collapse;">
                            <thead>
                                <tr>
                                    <th style="padding: 4px 8px 4px 0px; font-weight: bold; width: 200px;" />
                                    <th
                                        v-for="(columnTitle, index) in annualReportData.changesInEquity.columnTitles"
                                        :key="index"
                                        :style="{
                                            padding: hasNonZeroInColumn(index) ? '4px 8px 4px 8px' : '0px',
                                            fontWeight: 'bold',
                                            width: hasNonZeroInColumn(index) ? '100px' : '0px',
                                            textAlign: 'right',
                                            wordBreak: 'break-word',
                                        }"
                                    >
                                        <p v-if="hasNonZeroInColumn(index)">{{ columnTitle }}</p>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <table style="border-collapse: collapse;">
                            <thead>
                                <tr class="text-sm" v-for="(value, rowIndex) in annualReportData.changesInEquity.rows" :key="rowIndex">
                                    <template v-if="isEmptyRow(value)">
                                        <td
                                            :style="{
                                                width: '200px',
                                                fontWeight: rowIndex === annualReportData.changesInEquity.rows.length - 1 ? 'bold' : 'normal',
                                                wordBreak: 'break-word',
                                                padding: '4px 10px 4px 0px',
                                            }"
                                        >
                                            {{ value.description }}
                                        </td>
                                        <td
                                            v-for="(decimal, colIndex) in value.amounts"
                                            :key="colIndex"
                                            :style="{
                                                padding: isEmptyCol(decimal, rowIndex, colIndex) ? '4px 8px 4px 0px' : '0px',
                                                textAlign: 'right',
                                                width: isEmptyCol(decimal, rowIndex, colIndex) ? '100px' : '0px',
                                                fontWeight: colIndex === value.amounts.length - 1 || rowIndex === annualReportData.changesInEquity.rows.length - 1 ? 'bold' : 'normal',
                                            }"
                                        >
                                            <p v-if="isEmptyCol(decimal, rowIndex, colIndex) && decimal != 0">{{ decimal | swedishNumberFormat(true) }}</p>
                                            <p v-else-if="isEmptyCol(decimal, rowIndex, colIndex) && decimal == 0">{{ decimal }}</p>
                                        </td>
                                    </template>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <p v-if="annualReportData.changesInEquity.showChangesInEquityComment" class="pt-4 text-sm">{{ annualReportData.changesInEquity.changesInEquityComment }}</p>
                </div>
            </div>
        </div>
        <div class="border border-indigo-600 p-4 mt-2" v-if="annualReportData" style="min-height: 1200px;">
            <div class="flex justify-between p-8">
                <div class="flex flex-col">
                    <p>{{ clientInfo.name }}</p>
                    <p>Org.nr {{ clientInfo.registrationNumber }}</p>
                </div>
                <p>3 (8)</p>
            </div>
            <div v-if="annualReportData && annualReportData.incomeStatementAndProfitDistributions && annualReportData.addIncomeStatementAndProfitDistribution" class="p-8">
                <h2 class="pt-4 font-bold font-sm">Resultatdisposition</h2>
                <table style="border-collapse: collapse;">
                    <tr class="text-sm" v-for="(item, index) in annualReportData.incomeStatementAndProfitDistributions.profitDistributions" :key="index">
                        <td style="padding: 4px 8px 4px 0px">{{ item.profitDistributionTitle }}</td>
                        <td style="width: 120px; text-align: right;">{{ item.rightValue | swedishNumberFormat(true) }}</td>
                    </tr>
                </table>

                <p
                    class="text-sm pt-2"
                    v-if="annualReportData && annualReportData.incomeStatementAndProfitDistributions && annualReportData.incomeStatementAndProfitDistributions.shareholderDividend != null"
                >
                    Till aktieägare utdelas {{ annualReportData.incomeStatementAndProfitDistributions.shareholderDividend }} kronor per aktie
                </p>

                <p
                    class="text-sm pt-2"
                    v-if="annualReportData && annualReportData.incomeStatementAndProfitDistributions && annualReportData.incomeStatementAndProfitDistributions.boardAuthorizedToSetPaymentDate"
                >
                    Styrelsen föreslås bemyndigas att besluta om tidpunkt då utdelningen skall betalas.
                </p>

                <p class="text-sm pt-2" v-if="annualReportData && annualReportData.incomeStatementAndProfitDistributions && annualReportData.incomeStatementAndProfitDistributions.setPaymentDay">
                    Utbetalningen skall betalas till aktieägarna senast {{ formattedDate }}.
                </p>
                <p v-show="annualReportData.incomeStatementAndProfitDistributions.showStatementFromBoard" class="pt-4 text-sm pr-5 break-normal">
                    {{ annualReportData.incomeStatementAndProfitDistributions.statementFromBoard }}
                </p>
                <p v-show="annualReportData.addCompletion" class="pt-4 text-sm">{{ annualReportData.completion }}</p>
            </div>
        </div>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="default">Stäng</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        clientInfo: {
            type: Object,
        },
        annualReportData: {
            type: Object,
        },
    },
    methods: {
        closeModal() {
            this.$emit("close");
        },
        isEmptyRow(row) {
            const containsOnlyZeroAndNull = row.amounts.every(amount => amount === 0 || amount === null);
            const containsZero = row.amounts.includes(0);
            const containsNull = row.amounts.includes(null);
            return !(containsOnlyZeroAndNull && containsZero && containsNull);
        },
        hasNonZeroInColumn(index) {
            return this.annualReportData.changesInEquity.rows.some(row => row.amounts[index]);
        },
        isEmptyCol(decimal, rowIndex, colIndex) {
            return (decimal !== 0 || rowIndex !== this.annualReportData.changesInEquity.rows.length - 1) && this.hasNonZeroInColumn(colIndex);
        },
        formatAmount(amount) {
            if (typeof amount === "number") {
                return amount.toFixed(1);
            } else if (!isNaN(parseFloat(amount)) && isFinite(amount)) {
                return parseFloat(amount).toFixed(1);
            } else {
                return "Invalid amount";
            }
        },
    },
    computed: {
        formattedDate() {
            const date = new Date(this.annualReportData.incomeStatementAndProfitDistributions.setPaymentDayDate);
            return date.toLocaleDateString("en-CA");
        },
        formattedData() {
            if (!this.annualReportData || !this.annualReportData.rows) {
                return [];
            }

            return this.annualReportData.rows.map(row => {
                let rowData = { description: row.description };
                row.amounts.forEach((value, index) => {
                    rowData["column" + index] = value;
                });
                return rowData;
            });
        },
    },
};
</script>

<style scoped>
.year-header {
    width: 200px;
    text-align: left;
    word-break: break-word;
    padding: 4px 10px 4px 0px;
}
.year-cell {
    width: 100px;
    text-align: right;
    word-break: break-word;
    padding: 4px 10px 4px 0px;
}
.description-cell {
    width: 200px;
    font-weight: normal;
    word-break: break-word;
    padding: 4px 10px 4px 0px;
}
.amount-cell {
    width: 100px;
    font-weight: normal;
    word-break: break-word;
    text-align: right;
    padding: 4px 10px 4px 0px;
}
</style>
